#sub >  button {
    background: #876d40;
    width: 150px;
    border-style: none;
    border-radius: 5px;
    margin-left: 50%;
  } 

  #label {
    margin-left: 50%;
    color: white;
  }
  
  #sub > button:hover {
    cursor: pointer;
  }
  
  #sub > button:focus {
    border-color: transparent;
    outline: none !important;
    box-shadow: none !important;
  }
  
  .outsideCard {
    border-radius: 25px;
    background: #876d40;
    box-shadow: 2px 2px 4px var(--bs-purple);
    border-style: none;
  }
  
  .mainCard {
    border-radius: 25px;
    background: #636465;
  }
  
  .search {
    height: 50px;
    width: 250px;
    margin-top: 10%;
    margin-bottom: 20%;
     
  }

 .bau {
    height: 30px;
    width: 250px;
    margin-top: 10%;
    margin-bottom: 20%;
     
  }

  .clean {
    z-index: -10;
    position: absolute;
   
  }
  
  
  .plusIcon {
    font-size: 100px;
    color: #ffffff;
    opacity: 0.29;
  }
  
  
  @media (min-width: 360px) and (min-height: 360px) {
    .outsideCard {
      height: 150px;
      width: 200px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
  
  @media (min-width: 360px) and (min-height: 360px) {
    #settingsCol {
      visibility: hidden;
    }
  }
  
  @media (min-width: 360px) and (min-height: 360px) {
    #controlsCol {
      margin: auto;
      width: 300px;
    }
  }
  
  @media (min-width: 576px) and (min-height: 600px) {
    .outsideCard {
      height: 200px;
      width: 200px;
      margin-bottom: 15px;
      margin-left: -100px;
      margin-top: 60px;
    }
  }
  
  @media (min-width: 576px) and (min-height: 600px) {
    .mainCard {
      height: 350px;
      width: 500px;
      margin: auto;
    }
  }
  
  @media (min-width: 576px) and (min-height: 600px) {
    #settingsCol {
      visibility: hidden;
    }
  }
  
  @media (min-width: 576px) and (min-height: 600px) {
    #controlsCol {
      width: 200px;
    }
  }
  
  @media (min-width: 768px) {
    .mainCard {
      height: 350px;
      width: 450px;
      margin: auto;
    }
  }
  
   @media (min-width: 768px) {
    .outsideCard {
      height: 200px;
      width: 200px;
      margin-bottom: 15px;
      margin-left: -150px;
      margin-top: 60px;
    }
  }
  
  @media (min-width: 768px) {
    #settingsCol {
      margin: auto;
      visibility: hidden;
    }
  }
  
  @media (min-width: 768px) {
    #controlsCol {
      width: 220px;
    }
  }
  
  @media (min-width: 992px) {
    .mainCard {
      height: 350px;
      width: 550px;
      margin: auto;
    }
  }
  
  @media (min-width: 992px) {
    .outsideCard {
      height: 200px;
      width: 200px;
      margin-bottom: 15px;
      margin-left: -150px;
      margin-top: 60px;
    }
  }
  
  @media (min-width: 992px) {
    #settingsCol {
      margin: auto;
      visibility: visible;
      width: 150px;
      margin-left: 60px;
    }
  }
  
