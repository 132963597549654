
.header_iner .header_right .header_notofication_wrap {
    margin-left: 40px;
    margin-right: 50px;
}


#bell {
    background-color: none;
}

.Menu_NOtification_Wrap {
    background: #fff;
    box-shadow: 0 10px 15px rgb(6 0 8 / 22%);
    border-radius: 10px;
    position: absolute;
    right: 0;
    width: 350px;
    transform: translateY(30px) translateX(20px);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.Menu_NOtification_Wrap .notification_Header {
    padding: 15px 20px;
    border-radius: 10px 10px 0 0;
    background: #2d1967;
}

#drop {
    right: 0;
    box-shadow: 0 10px 15px rgb(6 0 8 / 22%);
    border-radius: 10px;
    padding-top: 0%;
    position: absolute;
    width: 300px;
    transform: translateY(9px) translateX(5px);
    transition: .3s;

}

#drophead {
    padding: 15px 20px;
    border-radius: 10px 10px 0 0;
    background: #9a8258;
    color:white;
   
}

h6#create {
    margin-top: 8px;
    padding: 10px;
}


#crfile {
    margin-left: 10px;
}

i#iconew {
    color: #9a8258;
}

span#draf {
    transform: translate(2px, -11px);
}


a#sair {
    width: max-content;
}


.fixed-sidebar {
    position: fixed;
    top: 70px; /* Adjust this value according to the top bar's height */
    bottom: 0;
    width: 75px; /* Set the width of the sidebar as needed */
    background-color: #333; /* Sidebar background color */
    /* transition: transform 0.3s;
    transform: translateX(-100%); */
    z-index: 1000; /* Ensure it's above other content */
    background-color: rgb(33 37 40);
    color: white; /* Text color */ 
  
    /* Rest of your styles for the sidebar */
  }
  
  .toggled {
    transform: translateX(0);
  }

  li {
    list-style-type: none;
  }

  ul {
    padding-left: 1rem;
}

mt-3 {
    margin-left: -1rem;
}

/* Add this CSS to center the icon and the name */
.vertical-sidebar-icons .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .vertical-sidebar-icons .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
    padding: 10px; /* Adjust the padding as needed */
  }
  
  .vertical-sidebar-icons .nav-item .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  
  .vertical-sidebar-icons .nav-item i {
    font-size: 24px; /* Adjust the icon size as needed */
  }
  
  .vertical-sidebar-icons .nav-item small {
    font-size: 12px; /* Adjust the font size as needed */
    margin-top: 5px; /* Adjust the margin as needed */
  }

  .component {
    padding-left: 87px;
    padding-right: 21px;
    margin-top: 90px;
   
}

.offcanvas {
    --bs-offcanvas-width: 190px;
    margin-top: 69px;
}

#root {
    background-color: rgb(234 229 213);
}

html {
      background-color: rgb(234 229 213);

}

.fixed-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
}