li #file-items {
    list-style: none;
    margin: 1.2em 0;
    background-color: #f55e30 38;
    border-radius: 5px;
    align-items: center;
    /* padding: 1.2em 1.5em; */
}

.icon {
    color: #f55321;
}
li svg:first-child {
    font-size: 1.2em;
    margin-right: 0.8em;
    cursor: pointer;
}
li p {
    flex: 1;
    font-size: 0.9rem;
    margin-bottom: 0;
}
li .actions {
    justify-self: flex-end;
    cursor: pointer;
}
li  .actions .fa-spinner {
    font-size: 1.2em;
}
