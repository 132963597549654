.flex-container {
  display: flex;
  justify-content: space-between; /* Aligns items to the beginning and center */
  background-color: #eae5d594;
  border-radius: 10px;
  box-shadow: inset 1px -3px 6px rgb(82,86,89);
}

.flex-item-1 {
  padding-top: 20px;
  padding-left: 20px;
  /* Addmtitional styles for the first item if needed */
}

.flex-item-2 {
  padding-top: 10px;
  margin: auto; /* Centers the second item horizontally */
  /* Additional styles for the second item if needed */
}

.flex-item-3 {
    width: 15%;
}


.track {
    position: relative;
    background-color: #ddd;
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px;
  }
  .track .step {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 25%;
    margin-top: -18px;
    text-align: center;
    position: relative;
  }
  .track .step.active:before {
    background: #ff5722;
  }
  .track .step::before {
    height: 7px;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 18px;
  }
  .track .step.active .icon {
    background: #ee5435;
    color: #fff;
  }
  .track .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #ddd;
  }
  .track .step.active .text {
    font-weight: 400;
    color: #000;
  }
  .track .text {
    display: block;
    margin-top: 7px;
  }
  .itemside {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .itemside .aside {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .img-sm {
    width: 80px;
    height: 80px;
    padding: 7px;
  }
  ul.row,
  ul.row-sm {
    list-style: none;
    padding: 0;
  }
  .itemside .info {
    padding-left: 15px;
    padding-right: 7px;
  }
  .itemside .title {
    display: block;
    margin-bottom: 5px;
    color: #212529;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .btn-warning {
    color: #ffffff;
    background-color: #ee5435;
    border-color: #ee5435;
    border-radius: 1px;
  }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #ff2b00;
    border-color: #ff2b00;
    border-radius: 1px;
  }
  .cardTrack {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    padding-bottom: 5%;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
  }
  


/* body{
    background-color: #7c7e82;
    margin-top:20px;
}
.card {
    margin-bottom: 24px;
    -webkit-box-shadow: 0 2px 4px rgb(126 142 177 / 10%);
    box-shadow: 0 2px 4px rgb(126 142 177 / 10%);
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #eaedf1;
    border-radius: .25rem;
}

.activity-wid {
    margin-left: 16px;
}
.mb-0 {
    margin-bottom: 0!important;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.activity-wid .activity-list {
    position: relative;
    padding: 0 0 33px 30px;
}

.activity-border:before {
    content: "";
    position: absolute;
    height: 38px;
    border-left: 3px dashed #eaedf1;
    top: 40px;
    left: 0;
}

.activity-wid .activity-list .activity-icon {
    position: absolute;
    left: -20px;
    top: 0;
    z-index: 2;
}
.avatar-sm {
    height: 2.5rem;
    width: 2.5rem;
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.me-3 {
    margin-right: 1rem!important;
}

.font-size-15 {
    font-size: 15px!important;
}

.font-size-14 {
    font-size: 14px!important;
}
.text-muted {
    color: #74788d!important;
}

.text-end {
    text-align: right!important;
}

.font-size-13 {
    font-size: 13px!important;
}

.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}
.bg-soft-primary {
    background-color: rgba(82,92,229,.25)!important;
}
.bg-soft-success {
    background-color: rgba(35,197,143,.25)!important;
} */
