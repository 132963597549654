* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

#arquive {
    margin: 0;
    font-family: 'Open-Sans',sans-serif;
    color: #343a4a;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding: 80px; 
}

.title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-select__menu {
  z-index: 10000000; /* or any higher value as needed */
}